<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Borrowers & Licensees
        </strong>
        <hwa-button
          icon="PlusIcon"
          label="Add Association"
          @onClick="addNewAssociation()"
        />
      </div>
      <b-table
        small
        selectable
        select-mode="single"
        :fields="fields"
        :items="borrower_with_licensees"
        responsive="sm"
        @row-clicked="openModal"
      >
        <template #cell(borrower_full_name)="data">
          {{ data.value }}
        </template>
        <template #cell(borrower_email)="data">
          {{ data.value }}
        </template>
        <template #cell(licensee_full_name)="data">
          {{ data.value }}
        </template>
        <template #cell(licensee_email)="data">
          {{ data.value }}
        </template>
      </b-table>
    </b-card>

    <!-- File details -->
    <b-modal
      id="show-modal"
      title="Associated Licensee Details"
      hide-footer
    >

      <b-alert
        variant="secondary"
        show
        class="mb-2"
      >
        <div class="alert-body" v-if="selected_borrower">
          <div class="associte_user">
            <b-avatar variant="success">
              <feather-icon icon="UserIcon" />
            </b-avatar>
            <div class="tag-view">
              <div class="text-item">
                <h5>{{ borrower.full_name }}</h5>
                <span class="email">{{ borrower.email }}</span>
              </div>
              <div>
                <b-badge
                  variant="success"
                  class="badge-glow"
                >
                  borrower
                </b-badge>
              </div>

            </div>

          </div>

          <hr>

          <div class="associte_user" v-if="selected_licensee">
            <b-avatar variant="warning">
              <feather-icon icon="UserIcon" />
            </b-avatar>
            <div class="tag-view">
              <div class="text-item">
                <h5>{{ licensee.full_name }}</h5>
                <span class="email">{{ licensee.email }}</span>
              </div>
              <div>
                <b-badge
                  variant="warning"
                  class="badge-glow"
                >
                  licensee
                </b-badge>
              </div>

            </div>

          </div>
        </div>
      </b-alert>
      <span>Change borrower</span>
      <b-form-select
        v-model="selected_borrower"
        placeholder="Select Borrower"
        :options="borrower_options"
        class="mb-2"
      />
      <span>Change licensee</span>
      <b-form-select
        v-model="selected_licensee"
        placeholder="Select Licensee"
        :options="options"
        class="mb-2"
      />

      <div class="d-flex justify-content-between">
        <b-button
        v-if="updateMode"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-danger"
        class="float-right"
        @click="deleteAssociateLicensees(details.id)"
      >
        Delete
      </b-button>
      <div v-else></div>
        <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="float-right"
        @click="updateMode?update():create()"
      >
        Save Changes
      </b-button>
      </div>
 
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BCard,
  VBTooltip, BButton, BModal, BFormSelect, BAlert, BAvatar,
  BBadge,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import realestateLicensee from '@/mixins/realestateLicensee'
import Ripple from 'vue-ripple-directive'
import HwaButton from '@/layouts/components/HwaButton.vue'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BModal,
    BFormSelect,
    BAlert,
    BAvatar,
    BBadge,
    HwaButton
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [showToast, realestateLicensee],
  data() {
    return {
      fields: [{key:'borrower_full_name',label:"Borrower Full Name",sortable:true}, 'borrower_email', {key:'licensee_full_name',label:"Licensee Full Name",sortable:true}, 'licensee_email'],
      reviewLoading: false,
      updateReviewMode: false,
      reviewModalOpened: false,
      details: {},
      updateMode: false
    }
  },
  computed:{
    borrower(){
      return this.borrowers.find((b)=>b.id = this.selected_borrower)
    },
    licensee(){
      return this.licensees.find((b)=>b.id = this.selected_licensee)
    },
    options(){
      return this.licensees.map(item => ({ value: item.id, text: item.full_name }))
    },
    borrower_options(){
      return this.borrowers.map(item => ({ value: item.id, text: item.full_name }))
    }
  },
  mounted() {
    this.getRealestateLicensees()
    this.getBorrowers()
    this.getBorrowersWithLicensees()
  },
  methods: {
    addNewAssociation(){
      this.updateMode = false
      this.details = {}
      this.selected_licensee = null
      this.selected_borrower = null
      this.$bvModal.show('show-modal')
    },
    openModal(items) {
      this.updateMode = true
      this.details = items
      this.selected_licensee = items.licensee_id
      this.selected_borrower = items.borrower_id
      this.$bvModal.show('show-modal')
    },
  },
}
</script>
